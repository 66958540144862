.properties-slideshow-item{
  height: 550px;
  position: relative;

  &:hover{
    .properties-slideshow-item-body{
      bottom:0.5rem;
      opacity: .9;
    }
  }


}

.properties-slideshow-item-body{
  padding:1.5rem;
  //box-shadow: 0 10px 20px rgba(0,0,0, 5%);
  transition: all .4s ease 0s;
  width: 350px;
  position:absolute;
  bottom:1.5rem;
  left:1.5rem;
  background: @properties-slideshow-item-body-bg;
  border-radius: @border-radius;

  .price {
    font-size:28px;
    font-weight: 500;
    color:@properties-slideshow-item-body-price-color;
    span{
      font-size:50%;
    }
  }

  .location{
    margin-bottom:5px;
    font-size:13px;
    margin-top:10px;
    color: @properties-slideshow-item-body-color;
    //font-weight: bold;
  }
  svg{
    fill:@properties-slideshow-item-body-svg-fill;
  }

  .propertygrid-meta-wrapper{
    border: 2px solid fade(@properties-slideshow-item-body-color, 20%);
    border-radius:5px;
    padding:10px;
    margin-top:10px;
  }

  .property-title{
    margin-top:13px;
    display:block;
    color: @properties-slideshow-item-body-title-color;
    font-size: @property-card-body-title-size;
    height:50px;
    overflow: hidden;
    font-weight:600;
    line-height: 120%;
  }

  .propertygrid-meta {
    margin-right: 15px;
    position: relative;
    padding-right: 15px;
    color: @properties-slideshow-item-body-color;
    font-weight: bold;
    font-size: 15px;

    &:before {
      position: absolute;
      right: 0;
      background: rgba(0, 0, 0, .1);
      height: 20px;
      width: 1px;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;

      &:before {
        display: none;
      }
    }
  }
}



.properties-slideshow{
  .owl-stage .owl-item:nth-child(odd) > div  {
    border-top-right-radius: @border-radius;
    border-bottom-right-radius: @border-radius;

  }

  .owl-stage .owl-item:nth-child(even) > div {
    border-top-left-radius: @border-radius;
    border-bottom-left-radius: @border-radius;

  }
}

