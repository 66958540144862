.marker-cluster-small {
 // background-color: rgba(181, 226, 140, 0.8);
}
.marker-cluster-small div {
  //background-color: rgba(110, 204, 57, 0.8);
 // background-color: rgba(241, 128, 23, 0.8);
}

.marker-cluster-medium {
 // background-color: rgba(241, 211, 87, 0.8);
}
.marker-cluster-medium div {
  //background-color: rgba(240, 194, 12, 0.8);

  // background-color: rgba(241, 128, 23, 0.8);}
}
.marker-cluster-large {
 // background-color: rgba(253, 156, 115, 0.8);
}
.marker-cluster-large div {
 // background-color: rgba(241, 128, 23, 0.8);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
 // background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
 // background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  //background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 999px;

}
.marker-cluster div {
  width: 50px;
  height: 50px;
  //margin-left: 5px;
  //margin-top: 5px;

  text-align: center;
  border-radius: 999px;
  font-size: 15px;
  background-color: rgba(241, 128, 23, 0.9);
  color: #fff;

}
.marker-cluster span {
  line-height: 50px;
}

.osm-marker-icon{
  border-radius: 999px;
  height: 30px !important;
  width: 30px !important;
  background-color: #EE284B;
}