.header-band{
  padding:150px 0 30px 0;
  margin:0;
  background:@brand-color;
}

.header-band.header-with-image{
  position: relative;
  display:block;
  h1,h2,h3{
    color: #fff;
  }
  .container{
    position: relative;
  }
  .header-band-image{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    opacity: .7;
    background-size: cover;
  }
}