.label-wrapper{
  position: absolute;
  left:10px;
  top:10px;
  display: block;
  z-index: 1;

  .label{
    padding:3px 10px;
    font-size: 11px;
    color: #fff;
    background: @brand-color2;
    border-radius: 20px;
    font-weight: bold;
  }
}