.property-show-contact-short{
  width:100%;
}

.property-show-contact-short{
  max-width: 600px;
}
.property-show-contact-short-title{
  font-size:1.5rem;
  font-weight: bold;
  display: block;
  margin-bottom:1rem;
  font-family: @font-title;
  //margin-top: 3rem;
  color: @property-show-contact-short-title-color;
}

.contact-box-item{
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  font-weight: 600;

  a{
    color: @body-color;
    text-decoration: none;
  }
}

.contact-box-icon{

  background-color: @contact-icon-bg;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right:10px;

  svg{
    width:1.3rem;
    height:1.3rem;
    fill: @contact-icon-color;
  }
}

.footer .contact-box-item a{
  color: #fff;
}