body {
  -webkit-font-smoothing: antialiased;
  // font-size: 15px;
  font-size: 0.9rem;
  font-family: @font-main;
  background-color: @body-bg-color;
  line-height: 160%;
  min-height: 100%;
  color: @body-color;
}

a:hover{
  color: @brand-color2;
}

h1,h2,h3{
  font-family: @font-title;
  //font-weight: 500;
  //color: @site-footer-title-color;

}

h1{
  // font-size: 2.7rem;
  margin:30px 0;
}

h2{
  margin:20px 0;
  font-size: ~"calc(1.125rem + .9vw)";
}

h1.container-title,h2.container-title{
  position: relative;
  padding-left:35px;
  span{
    color:@brand-color2;
  }
  &:after{
    content:" ";
    display:block;
    width: 25px;
    height: 25px;
    position:absolute;
    top:5px;
    left:0;


    background: conic-gradient(from 180deg at 48.5% 50%, #989DA3 -46.16deg, #101012 130.63deg, #9DA2A9 133.38deg, #121214 311.43deg, #989DA3 313.84deg, #101012 490.63deg);
    border-radius: 30px;
    transform: rotate(90deg);
  }
}
.center-title h1, .center-title h2{
  &:after{
    content:" ";
    display:none;
  }
}

.border-8{
  padding:8px !important;
}

.bignumber{
  color: @brand-color2;
  font-size:~"calc(2.225rem + .9vw)";
  display:block;
  margin-bottom:20px;
}