.divider-simple{
  padding-top:50px;
  padding-bottom:50px;
}

.divider-grey{
  padding-top:50px;
  padding-bottom:50px;
  background: @brand-color;
  color: #fff;
  h1,h2,h3{
    color: #fff;
  }
}

.divider-center{
  .container-title{
    text-align: center;

    &:after{
      display: none;
    }
  }
}

.divider-lightgrey {
  padding-top:50px;
  padding-bottom:50px;
  background: @brand-grey;
}

.divider-fullscreen-margin{
  margin:0 15px;
}

.divider-fullscreen-padding{
  padding-left:15px;
  padding-right:15px;
}

.divider-fullscreen-title-margin h1, .divider-fullscreen-title-margin h2{
  margin-left:15px;
}

.dark-container{
  background: @brand-color;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}

.light-container{
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}