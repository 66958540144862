.modal-search {
  --bs-modal-bg: @site-search-modal-bg;
  --bs-modal-header-border-color:@site-search-modal-header-border;
  --bs-modal-footer-border-color:@site-search-modal-footer-border;

  h3 {
    color: @brand-color2;
  }

  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .search-form-wrapper {
    padding: 0;
  }

  .search-item {
    margin-top: 25px;
  }

  .form-group-picker {
    width: 100%;
  }

  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }

  .search-inner {
    .form-group-picker .btn, .form-control {
      background: @site-properties-search-form-input-background;
      color: @site-properties-search-form-input-color;
    }
    .form-control{
      border:@site-properties-search-form-input-border;
    }

    .btn-search-item{
      border:@site-properties-search-form-input-border;
      //background: rgba(255, 255, 255, .1);
    }
  }
}





.search-form{
  label{
    color: @site-search-form-label-color;
  }

  .search-item{
    margin-top:10px;
  }
}

.search-form-wrapper{
  position:relative;
  .icon{

    svg{
      fill: @site-search-svg-fill;
      width:20px;
      height: 20px;
    }
  }
}

.shortsearchform .btn-check-transaction{
  border-radius:@border-radius;
  border-bottom-right-radius:0;
  border-bottom-left-radius: 0;
  border:0;
  padding:20px 40px;
}

.shortsearchform{

  .btn-check-transaction{
    background: @site-search-btn-bg;
    color:@site-search-btn-color;
  }

  .btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.show, .btn:active {
    background: @site-search-btn-check-bg;
    color:@site-search-btn-check-color;
  }

  .dropdown-toggle::after{
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

.search-inner{
  background: @site-search-bg;
  padding:20px;
  border-radius: @border-radius;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  border-top-left-radius:0;

  .form-group-picker{
    //width:200px;
    position:relative;

    .icon{
      position:absolute;
      top:5px;
      left:10px;
    }
  }

  .bootstrap-select{

  }


  .dropdown-menu{
    --bs-dropdown-bg: @brand-color;
    --bs-dropdown-color: #fff;
    --bs-dropdown-link-color: #fff;
    --bs-dropdown-link-hover-bg: rgba(255,255,255,.1);
    --bs-dropdown-link-hover-color: #fff;
  }

  .form-group-picker .btn{
    padding-left:40px;
    padding-right:10px;
    border:none;
    background: none;
    color: @site-search-picker-color;
    outline:none !important;
    box-shadow:none;
    // border-radius:0;
    &.show{
      background:rgba(255,255,255,.1);
    }
  }
  .form-control{
    background: none;
  }

  .btn-search{
    background: @site-search-btn-search-bg;
    border:@site-search-btn-search-border;
    border-radius:5px;
    color: @site-search-btn-search-color;
    padding:10px 30px;

    &:hover{
      border:1px solid darken(@brand-color2,30%);
      color:#fff;
      background: @brand-color2;
    border: @site-search-btn-search-hover-border;
    }
    svg{
      width:20px;
      height: 20px;
      fill: #fff;
      margin-right:5px;
    }
  }
}


.short-search-line{
  height:50px;
  width:1px;
  display:block;
  border-left: @site-search-line-color;
  margin:0 10px;
}

@media(max-width: @screen-md)
{
  .short-search-line{
    display:none;
  }

  .btn-search{
    min-width:200px;
  }
}


@media(max-width: @screen-xs)
{
  .search-form {
    .btn-search {
      width: 100%;
      margin-top: 20px;
    }

    .form-group-picker {
      width: 100%;
      margin-top: 20px;
    }

    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%;
    }
  }
}