.text-icon{
  background: #fff;
  border-radius: 0.375rem;
  text-align:center;
  padding: 1.875rem;
  box-shadow: 0 3px 20px 0 rgba(8,15,52,0.06);
  margin: 1.875rem auto;
  color: @icontext-color;
  flex-grow:1;
  h1,h2,h3,h4{
    color: @icontext-color-title !important;
  }

  a{
    font-size: 1rem;
    font-weight: bold;
    color:@icontext-color-link;
    text-decoration:none;
  }


  .text-icon-icon{
    background: #f5f8fa;
    display: inline-flex;
    width:100px;
    height: 100px;
    border-radius: 9999px;
    justify-content: center;
    align-content: center;
    margin-bottom:30px;
  }

  .text-icon-body{
    min-height: 120px;
  }
  svg{
    width:40px;
    height: 40px;
    align-self:center;
  }
}