.agent-info-box{
  position: relative;
  .agent-info-box-content{
    position:absolute;
    bottom:0;
    width:100%;
    height: 100px;
    #gradient.vertical(fade(@brand-color,0%), fade(@brand-color,80%));

    .agent-info-box-content-inner {
      position:absolute;
      bottom:20px;
      padding:0 15px;
      .agent-info-box-title {
        font-size: 18px;
        font-weight: 600;
      }

      ul {
        display: none;
      }
    }
  }
}