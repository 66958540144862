.social-media-wrapper{
  display:flex;
  gap: 5px;
  padding-bottom:20px;

  .contact-social-item{
   // border:@share-icon-border;
   // background: @share-icon-bg;
    svg{
      width:25px;
      height:25px;
      //fill:@share-icon-color;
    }
  }
}