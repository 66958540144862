.properties-sort{
  display: flex;
  background:@site-properties-sort-bg;
  padding:10px;
  position:relative;
  margin-bottom:15px;
  margin-top:-25px;
  justify-content: space-between;
 border-radius:10px;

}

.bootstrap-select.select-ordering{
  width:auto !important;
}

.sort-form-inner{
  display:flex;
  gap:10px;
}

.container-with-map{
  .properties-sort{
    margin-top:0px;
  }
}