footer{
  background: @site-footer-bg;
  color: fade(@site-footer-color,90%);
  fill:  fade(@site-footer-color,90%);
  padding-top:50px;
  padding-bottom:30px;

  h1,h2,h3,h4{
    color: @site-footer-title-color;
    font-size:22px;
    font-weight: 700;
  }
  a{
    color: @site-footer-color;
    text-decoration:none;
    font-weight:bold;
  }

  ul {
    padding-left:0;

    li {

      list-style: none;
    }
  }
}
.footer-partner{
  svg{
    width:25px;
    height: 25px;
  }
}

.footer-bottom{
  margin-top:20px;
 // opacity: .8;
  font-size:13px;
}

.footer-bottom::after {
    content: "";
    clear: both;
    display: table;
  }
