.mobile-contact-wrapper{
  //box-shadow: 0 2px 10px rgb(0,0,0, 0.3);
  //border-top: 1px solid #E1E3E7;
  animation-delay: 0.75s;
  animation: slide 0.5s forwards;
  background-color: rgba(255,255,255,.9);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: auto;
  will-change: transform;
  z-index: 1000;
  padding:5px 10px 10px 10px;
  display:none;
}

.mobile-contact{
  border-radius:999px;
  width:100%;
  padding:5px 10px;
  background: @brand-color2;
  color: #fff;
  svg{
    fill:#fff;
  }
  margin-top:5px;
}

.mobile-contact-phone{
  background: @mobile-contact-phone-bg;
}

.mobile-contact-message{
  background: @mobile-contact-message-bg;
}

@media(max-width: @screen-xs) {
  .mobile-contact-wrapper {
    display: block;
  }
}