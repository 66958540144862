.share-wrapper{
  display:flex;
  gap: 5px;
  padding-bottom:20px;

  .btn{
    border:@share-icon-border;
    background: @share-icon-bg;
    svg{
      width:25px;
      height:25px;
      fill:@share-icon-color;
    }
  }

  .social-whatsapp svg{
    fill: #25D366;
  }

  .social-facebook svg{
    fill: #4267B2;
  }

  .social-twitter svg{
    fill: #1DA1F2;
  }

  .social-linkedin svg{
    fill: #0077b5;
  }
}