.header-wrapper{
  position:absolute;
  top:0;
  left:0;
  right:0;
}

header{
  background:@header-bg;
  z-index: 20;
}
.logo{
  max-height: 170px;
}

@media(max-width: @screen-md)
{
  header{
    height: 80px;
  }
  .logo{
    max-height: 80px;
  }
}