.sticky-top-supershort-agent{
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 1020;
}

.agent-info-box-super-super-short{
  display: flex;

  .agent-info-box-super-short-image{
    display:block;
    width:60px;
    height: 60px;
    border-radius: 999px;
    overflow:hidden;
  }

  .agent-info-box-super-short-content{
    display:flex;
    align-items: center;
  }

  .agent-info-box-super-short-content-inner{
    display:flex;
  }
}


.agent-contact-wrapper{

}

.agent-info-box-super-short-title{
  margin-left:20px;
  font-size: 0.9rem;
  font-weight: bold;


}

.agent-info-super-short-phone{
  font-size:1.2rem;
  font-weight: bold;
  color: @brand-color2;
  text-decoration: none;
  margin-left:20px;
}
.agent-info-box-short{
  position: relative;
  height: 390px;
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56 , 0.1);
  overflow: hidden;
  border-radius: 10px;
  display:flex;
  align-items: center;
  max-width: 260px;
  margin-bottom:20px;

  .agent-info-box-short-image{
    img{
      max-height: 400px;
    }
  }


  .agent-info-box-short-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 46, 56, 0)), color-stop(51%, rgba(40, 46, 56, 0)), color-stop(72%, rgba(40, 46, 56, 0.50)));
    background-image: linear-gradient(180deg, rgba(40, 46, 56, 0), rgba(40, 46, 56, 0) 51%, rgba(40, 46, 56, 0.50) 72%);
  }

  .agent-info-box-short-content-inner{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    padding-right: 24px;
    padding-left: 24px;
    color: #fff;

  }

  .agent-info-box-short-title{
    font-size:1.2rem;
    font-weight: bold;
  }

  .agent-info-phone{
    font-size:1.1rem;
    font-weight: bold;
    margin-top:10px;
    color: @brand-color2;
    text-decoration: none;
    display:inline-block;
  }


}

@media(max-width: @screen-sm) {
  .agentinfo-col{
    justify-content: center;
  }
  .agent-info-box-short {
      height:250px;
      max-width:250px;
     justify-content: center;
    margin:0 auto 20px;
  }

  .property-show-contact-short-title{
   font-size:1.2rem !important;
  }

  .contact-form .btn{
    width:100%;
  }
}