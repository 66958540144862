.news-box-wrapper{
  padding-top:30px;
}

.news-box{
  transition: all .4s ease 0s;
  border-radius: 10px;
  overflow: hidden;
  background: fade(@brand-grey, 30%);
  &:hover{
    box-shadow: 0 5px 20px rgba(0,0,0, 20%);
  }
}

.news-box-image{
  position: relative;
}

.news-box-item-date{
  position:absolute;
  bottom: -10px;
  left: 20px;
  display: block;
  color: @article-date-color;
  background-color: @article-date-bg;
  padding: 8px 15px;
  border-radius: 5px;

  font-weight: 600;
  font-size: 24px;

  span{
    display:block;
    font-size:16px;
  }

}

.news-box-short-info{
  padding:1.3rem;

  h3{
    font-size:22px;
    color:@brand-color;

    a{
      color:@brand-color;
      text-decoration: none;
    }
  }
}


.article-wrapper{
  margin:50px auto 50px;
}

.article-date{
  display: block;
  color: @article-date-color;
  background-color: @article-date-bg;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
 // margin-top:-40px;
 // margin-left:20px;
  position: absolute;
  bottom:-20px;
  right:15px;

  svg{
    fill:#fff;
    width:20px;
    opacity: .7;
    height: 20px;
    margin-right:5px;

  }
}

.article-content{
  margin-top:20px;
}

.article-image{
  position:relative;
}

.module-latestnews{
  margin-top:40px;
}

.news-box-shortlist-item{
  border-radius:10px;
  overflow: hidden;
  background: fade(@brand-grey, 30%);
  display:block;
  margin-top:10px;
}

.news-box-shortlist-item-title{
  padding: 10px;
  font-size:16px;
  display:block;
  color: @brand-color;
  text-decoration: none;

}

.blog-article-image{
  border-top-left-radius: @border-radius;
  border-top-right-radius: @border-radius;
}