.mapping-wrap{
  position:relative;
}
.mapping-wrap .hover-path{
  opacity: 1;
  fill: rgba(99,145,170, 0.6);
  transition: all 0.2s ease-in-out;
}



.mapping-wrap .hover-path:hover{
  opacity: 1;
  fill: rgba(47, 51, 56, 0.6);
}

.mapping-prev{
  position:absolute;
  top:50%;
  height: 50px;
  width:50px;
  padding:5px;
  background: rgba(0,0,0,.6);
  text-decoration: none;
  color: #fff;
  transition: all .6s ease 0s;
  opacity: .5;
  &:hover{
    opacity: 1;
    width:auto;
    color: #fff;
    span{
      display:inline-block;
    }
  }
  svg{
    fill:#fff;
    stroke:#fff;
    height:40px;
    width:30px;
  }
  span{
   display:none;
   text-transform: uppercase;
    font-size:11px;
    font-weight: bold;
  }
}