.home-about{
  background: @brand-grey;
  padding:1.5rem;
  margin-top:-50px;
  margin-bottom:-50px;

  border-top-left-radius: @border-radius;
  border-bottom-left-radius: @border-radius;
  p{
    max-width: 800px;
  }
  padding-left:70px;
}

@media(max-width: @screen-md)
{
  .home-about{
    padding-left:20px;
  }
}